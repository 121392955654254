<template>
  <AuthWrapper>
    <v-card-text>
      <v-row align-content="center" justify="center">
        <v-col cols="12">
          <div class="register-top d-flex flex-column my-4">
            <v-btn class="text-center mx-auto" color="secondary" dark fab large>
              <v-icon dark> mdi-lock-open</v-icon>
            </v-btn>
            <h3 class="text-h3 text-center mb-3 mt-3">Register</h3>
          </div>
          <v-form v-model="registerFormValidated" @submit.prevent="registerUser">
            <v-text-field
              v-model="registerForm.firstName"
              :error-messages="errors.first_name"
              :rules="validationRules.first_name"
              outlined
              placeholder="First Name"
              label="First Name"
              color="secondary"
            />
            <v-text-field
              v-model="registerForm.lastName"
              :error-messages="errors.last_name"
              :rules="validationRules.last_name"
              outlined
              placeholder="Last Name"
              label="Last Name"
              color="secondary"
            />
            <v-text-field
              v-model="registerForm.mobile"
              :error-messages="errors.phone"
              :rules="validationRules.phone"
              outlined
              type="number"
              placeholder="Mobile"
              label="Mobile"
              color="secondary"
            />
            <v-text-field
              v-model="registerForm.email"
              :error-messages="errors.email"
              :rules="validationRules.email"
              outlined
              placeholder="Email"
              label="Email"
              color="secondary"
            />
            <v-text-field
              v-model="registerForm.password"
              :error-messages="errors.password"
              :rules="validationRules.password"
              outlined
              placeholder="Password"
              label="Password"
              color="secondary"
              :type="showPassword.main ? 'text' : 'password'"
              :append-icon="showPassword.main ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="togglePasswordShow('main')"
            />
            <v-text-field
              v-model="registerForm.confirmPassword"
              :error-messages="errors.password"
              :rules="confirmPasswordValidation"
              outlined
              placeholder="Confirm Password"
              label="Confirm Password"
              color="secondary"
              :type="showPassword.confirm ? 'text' : 'password'"
              :append-icon="showPassword.confirm ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="togglePasswordShow('confirm')"
            />
            <v-btn
              :disabled="!registerFormValidated"
              :loading="registerForm.submitting"
              block
              color="secondary"
              type="submit"
            >
              Submit
            </v-btn>
          </v-form>
          <div class="d-flex mt-3 mb-3 justify-space-between">
            <p> Already have Account?
              <router-link
                :to="{name: 'Login'}"
              >
                Login
              </router-link>
            </p>
          </div>

          <div class="d-flex mx-auto flex-column">
            <h4 class="text-h6 text-center mt-3 mb-5 d-block">OR</h4>
            <v-btn :to="{name : 'Home'}" outlined>Back To Home</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </AuthWrapper>
</template>
<script>
import Auth from '@/api/Auth';
import Toast from '@/utils/Toast';
import AuthWrapper from '@/views/auth/AuthWrapper';
import {
  userEmailRule, userFirstNameRule,
  userLastNameRule, userPasswordRule, userPhoneNumberRule
} from '@/utils/Validation/validationRules';
import ErrorHelper from '@/utils/ErrorHelper';

export default {
  name: 'Register',
  components: { AuthWrapper },
  data(){
    return {
      showPassword: {
        main: false,
        confirm: false
      },
      registerFormValidated: false,

      registerForm: {

        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        password: '',
        confirmPassword: '',
        submitting: false,

      },

      errors: {

        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        password: '',

      },

      validationRules: {

        first_name: userFirstNameRule(),
        last_name: userLastNameRule(),
        phone: userPhoneNumberRule(),
        email: userEmailRule(),
        password: userPasswordRule(),

      },
    }
  },

  computed: {
    confirmPasswordValidation(){
      const password = this.registerForm.password;
      return userPasswordRule(password, true)
    }
  },

  methods: {
    async registerUser() {

      let data = {

        first_name: this.registerForm.firstName,
        last_name: this.registerForm.lastName,
        phone: this.registerForm.mobile,
        email: this.registerForm.email,
        password: this.registerForm.password,
        password_confirmation: this.registerForm.confirmPassword

      };

      let redirectPath = this.$route?.query?.redirect;
      let redirectTo = redirectPath ? { path: redirectPath } : { name: 'Home' };

      this.registerForm.submitting = true;

      try {

        await Auth.register(data);
        await this.$store.dispatch('app/fetchLoggedInUser');
        Toast.success('You have Successfully Registered');
        this.registerForm.submitting = false;
        return this.$router.push(redirectTo);

      } catch (e) {

        this.registerForm.submitting = false;

        ErrorHelper.mapServerError(e, (error, ex) => {
          this.errors.first_name = ex(error.first_name);
          this.errors.last_name = ex(error.last_name);
          this.errors.phone = ex(error.phone);
          this.errors.email = ex(error.email);
          this.errors.password = ex(error.password);
        })

        Toast.error(e.message || 'something went wrong');
      }
    },
    togglePasswordShow(field) {
      this.showPassword[field] = !this.showPassword[field];
    }
  },
  watch: {

    'registerForm.firstName': {
      handler(data) {
        if (data) {
          this.errors['first_name'] = [];
        }
      }
    },

    'registerForm.lastName': {
      handler(data) {
        if (data) {
          this.errors['last_name'] = [];
        }
      }
    },

    'registerForm.email': {
      handler(data) {
        if (data) {
          this.errors['email'] = [];
        }
      }
    },

    'registerForm.mobile': {
      handler(data) {
        if (data) {
          this.errors['phone'] = [];
        }
      }
    },
    'registerForm.password': {
      handler(data) {
        if (data) {
          this.errors['password'] = [];
        }
      }
    },
  },
};
</script>

<style lang="scss">
.register-page-content {
  height: 100%;
  min-height: 100%;
}

.register-left {
  background-image: url('../../assets/images/bg/login-bg.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
}
</style>
